import { useQueryParams } from 'submodules/nerit-framework-ui/common/hooks/useQueryParam'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ForgotPasswordStepsCP, ForgotPasswordStepsTP } from 'modules/auth/screens/screen-recover-password/inner/ForgotPasswordStepsCP'
import React, { useState } from 'react'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'
import { CertificatesFooterCP } from 'modules/auth/components/certificates-footer/CertificatesFooterCP'

export type ResetPasswordQueryParamsTP = { email: string; step: ForgotPasswordStepsTP }

/**
 * TELA: Recuperar senha.
 */
export function ScreenForgotPassword(): JSX.Element {
	const [queryParams] = useQueryParams<ResetPasswordQueryParamsTP>()
	const [passwordResetStep, setPasswordResetStep] = useState<ForgotPasswordStepsTP>(queryParams.step ?? ForgotPasswordStepsTP.EMAIL_INPUT)

	function handlePasswordResetStepChange(step: ForgotPasswordStepsTP): void {
		setPasswordResetStep(step)
	}

	if (!!AppStateUtils.getLoggedUser()) {
		return <RedirectCP path={PrivateUserRouter.HOME} />
	}

	return (
		<LayoutImageSiderCP
			sider={{
				width: '60%',
				imageUrl: `${process.env.PUBLIC_URL}/images/brCover4.png`,
			}}
			content={{
				logoUrl: ThemeProject.logoLoginUrl,
				logoWidth: 300,
			}}
		>
			<ForgotPasswordStepsCP onPasswordResetStepChange={handlePasswordResetStepChange} passwordResetStep={passwordResetStep} />

			<CertificatesFooterCP />
		</LayoutImageSiderCP>
	)
}
