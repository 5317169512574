import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import React, { useState } from 'react'
import { TableTemplatesCP } from 'modules/template/components/table-templates/TableTemplatesCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ModalTemplateCP } from 'modules/template/components/modal-template/ModalTemplateCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
	type: TemplateTypeEnum
	filters: {
		clinicCode?: number
		doctorGroupCode?: number
	}
}

/**
 * Lista de templates.
 */
export function TableTemplatesWithDataCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponseDTO>()

	const [loadList, setLoadList] = useState<number>(1)

	return (
		<>
			<FlexCP justifyContent={'flex-end'} margin={{ right: 20 }}>
				<ButtonCP
					onClick={() => {
						setSelectedTemplate(undefined)
						setIsModalVisible(true)
					}}
				>
					Novo Template de Laudo
				</ButtonCP>
			</FlexCP>

			<TableTemplatesCP
				loadList={loadList}
				filters={{
					type: props.type,
					clinicCode: props.filters.clinicCode,
					doctorGroupCode: props.filters.doctorGroupCode,
				}}
				onSelectTemplate={(template) => {
					setSelectedTemplate(template)
					setIsModalVisible(true)
				}}
				appearance={{
					showDeleteButton: true,
					showEditButton: true,
					wrappedOnCard: true,
				}}
			/>

			<ModalTemplateCP
				type={TemplateTypeEnum.TEMPLATE}
				onCancel={() => setIsModalVisible(false)}
				onSave={() => {
					setIsModalVisible(false)
					setLoadList(TableUtils.getReloadNumber())
				}}
				visible={isModalVisible}
				data={{
					template: selectedTemplate,
					initialValues: {
						owner: {
							doctorGroupCode: props.filters.doctorGroupCode,
							clinicCode: props.filters.clinicCode,
						},
					},
				}}
			/>
		</>
	)
}
