import React, { useState } from 'react'
import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { AddFileIconCP } from '_old/main/common/components/icons/AddFileIconCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'
import { IToolbarDefaultBtnProps } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/IToolbarDefaultBtnProps'
import { ModalTemplateCP } from 'modules/template/components/modal-template/ModalTemplateCP'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps extends IToolbarDefaultBtnProps {
	examModality?: ExamModalityEnum
}

/**
 */
export function ButtonToolbarAddPhraseICP(props: ICPProps): JSX.Element {
	const [isCreatePhraseModalVisible, setIsCreatePhraseModalVisible] = useState<boolean>(false)
	const [phraseToAdd, setPhraseToAdd] = useState<string>()

	return (
		<>
			<ToolbarButtonICP
				innerContent={<AddFileIconCP size={20} />}
				onClick={() => {
					setPhraseToAdd(TextEditorUtils.getSelectionText(props.editorState))
					setIsCreatePhraseModalVisible(true)
				}}
				isActive={() => !StringUtils.isEmpty(TextEditorUtils.getSelectionText(props.editorState))}
				isDisabled={() => StringUtils.isEmpty(TextEditorUtils.getSelectionText(props.editorState))}
			/>

			<ModalTemplateCP
				type={TemplateTypeEnum.PHRASE}
				visible={isCreatePhraseModalVisible}
				onCancel={() => setIsCreatePhraseModalVisible(false)}
				onSave={() => setIsCreatePhraseModalVisible(false)}
				data={{
					initialValues: {
						modalities: !!props.examModality ? [props.examModality] : undefined,
						content: phraseToAdd ?? '',
						owner: { doctorCode: AppStateUtils.getLoggedUser()!.code },
					},
				}}
			/>
		</>
	)
}
