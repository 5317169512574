import React from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { AppConfig } from 'config/AppConfig'
import { EXAM_SCREEN_FILTERS_PARAM } from 'modules/exams/screens/screen-exams/ScreenExams'
import { useParams } from 'react-router-dom'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCopyToClipboardCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCopyToClipboardCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

interface IRouteParams {
	examStatusGroup: ExamStatusGroupEnum
}

interface ICPProps extends BasicStyleWrapperCPProps {
	text: string
	icon: string
	selectedExamIds: string[]
	viewAsDoctor: boolean
}

export function ButtonShareCopyUrlToClipboardCP(props: ICPProps): JSX.Element {
	const { examStatusGroup } = useParams<IRouteParams>()

	function getUrl(): string {
		const filtersDto: ExamSearchRequestDTO = {
			viewAsDoctor: props.viewAsDoctor,
			doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
			examIds: props.selectedExamIds,
		}

		let path = `${AppConfig.getInstance().uiBaseUrl}/${AppStateUtils.getDomain()!}/${PrivateUserRouter.EXAMS.replace(
			':examStatusGroup?',
			examStatusGroup ?? '',
		)}`

		const queryParams = `${EXAM_SCREEN_FILTERS_PARAM}=${encodeURI(JSON.stringify(filtersDto))}`

		path = `${path}?${queryParams}`

		return path
	}

	return (
		<TooltipCP text="Copiar link de compartilhamento" showSpan>
			<ButtonCopyToClipboardCP label={props.text} icon={props.icon} textToCopy={getUrl()} margin={props.margin} />
		</TooltipCP>
	)
}
