import React from 'react'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { ClinicsAdminViewTP } from 'modules/clinic/screens/screen-clinics-admin/inner/ClinicsAdminViewTP'
import { ScreenContentClinicsAdminReportConfig } from 'modules/clinic/screens/screen-clinics-admin/content/ScreenContentClinicsAdminReportConfig'
import { ClinicsAdminScreenHeaderCP } from 'modules/clinic/screens/screen-clinics-admin/header/ClinicsAdminScreenHeaderCP'
import { ClinicsAdminDoctorGroupsScreenContent } from 'modules/clinic/screens/screen-clinics-admin/content/ClinicsAdminDoctorGroupsScreenContent'
import { ClinicsAdminUserScreenContent } from 'modules/clinic/screens/screen-clinics-admin/content/ClinicsAdminUserScreenContent'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ScreenContentClinicsAdminTechnical } from 'modules/clinic/screens/screen-clinics-admin/content/ScreenContentClinicsAdminTechnical'
import { ScreenContentClinicsAdminClinicData } from 'modules/clinic/screens/screen-clinics-admin/content/ScreenContentClinicsAdminClinicData'
import { CLINIC_CODE_PARAM } from 'modules/clinic/screens/screen-clinics-admin/ScreenClinicsAdmin'
import { TableTemplatesWithDataCP } from 'modules/template/components/table-templates-with-data/TableTemplatesWithDataCP'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { ScreenContentClinicsAdminReportPreviewConfig } from 'modules/clinic/screens/screen-clinics-admin/content/ScreenContentClinicsAdminReportPreviewConfig'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { AppStateUtils } from 'app/redux/AppStateUtils'

export const CLINICS_ADMIN_SUB_MENU_CONFIG_KEY = 'config'
export const CLINICS_ADMIN_SUB_MENU_USERS_KEY = 'users'
export const CLINICS_ADMIN_SUB_MENU_BILLING_KEY = 'billing'

/**
 * Reune metodos auxiliares para gestao da tela CONFIGURACOES.
 */
export const ClinicsAdminScreenUtils = {
	getSubMenuConfigs(onChangeClinic: (clinicCode: number) => void, clinicCode: number): Array<ScreenSubMenuTP<ClinicsAdminViewTP>> {
		return [
			{
				key: CLINICS_ADMIN_SUB_MENU_CONFIG_KEY,
				title: 'Configurações',
				items: [
					{
						name: 'Dados cadastrais',
						view: 'clinicDataView',
						route: `${PrivateUserRouter.CLINIC_DATA}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ScreenContentClinicsAdminClinicData clinicCode={clinicCode} />,
						contentTitle: 'Dados cadastrais',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
					{
						name: 'Configurações do Laudo',
						view: 'reportTemplateView',
						route: `${PrivateUserRouter.CLINIC_REPORT_CONFIG}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ScreenContentClinicsAdminReportConfig clinicCode={clinicCode} />,
						contentTitle: 'Configurações do Laudo',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
					{
						name: 'Modelo do Laudo',
						view: 'reportTemplatePreviewView',
						route: `${PrivateUserRouter.CLINIC_REPORT_PREVIEW}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ScreenContentClinicsAdminReportPreviewConfig clinicCode={clinicCode} />,
						contentTitle: 'Preview do Modelo do Laudo',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
					{
						name: 'Templates para Médicos',
						view: 'templateView',
						route: `${PrivateUserRouter.CLINIC_TEMPLATES}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <TableTemplatesWithDataCP type={TemplateTypeEnum.TEMPLATE} filters={{ clinicCode }} />,
						contentTitle: 'Templates para Médico Laudar',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
						enabled: ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser']),
					},
					{
						name: `Instalação (${AppStateUtils.getDomainName()} Box)`,
						view: 'technicalView',
						route: `${PrivateUserRouter.CLINIC_TECHNICAL_CONFIG}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ScreenContentClinicsAdminTechnical clinicCode={clinicCode} />,
						contentTitle: `Instalação (${AppStateUtils.getDomainName()} Box)`,
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
				],
				enabled: true,
			},
			{
				key: CLINICS_ADMIN_SUB_MENU_USERS_KEY,
				title: 'Acessos e Permissões',
				items: [
					{
						name: 'Grupos de médicos',
						view: 'doctorGroupView',
						route: `${PrivateUserRouter.CLINIC_DOCTOR_GROUPS}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ClinicsAdminDoctorGroupsScreenContent clinicCode={clinicCode} />,
						contentTitle: 'Grupos de médicos',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
					{
						name: 'Usuários',
						view: 'userView',
						route: `${PrivateUserRouter.CLINIC_USERS}?${CLINIC_CODE_PARAM}=${clinicCode}`,
						content: <ClinicsAdminUserScreenContent clinicCode={clinicCode} />,
						contentTitle: 'Usuários',
						contentTitleChildren: <ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={onChangeClinic} />,
					},
				],
				enabled: true,
			},
		]
	},
}
