import { TableTemplatesWithDataCP } from 'modules/template/components/table-templates-with-data/TableTemplatesWithDataCP'
import React from 'react'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'

interface ICPProps {
	type: TemplateTypeEnum
	filters: {
		clinicCode?: number
		doctorGroupCode?: number
	}
}

export function ScreenContentDoctorGroupTemplates(props: ICPProps): JSX.Element {
	return (
		<LayoutSubmenuContentCP>
			<TableTemplatesWithDataCP type={TemplateTypeEnum.TEMPLATE} filters={{ doctorGroupCode: props.filters.doctorGroupCode }} />
		</LayoutSubmenuContentCP>
	)
}
