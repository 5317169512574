import { REQUIRED_PADI_VALUES } from 'modules/clinic/components/tabs-edit-medical-report-config/constants'
import React, { useEffect, useState } from 'react'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { MedicalReportVarsEnum } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportVarsEnum'
import { EditorCodeCP } from 'submodules/nerit-framework-ui/common/components/editor-code/EditorCodeCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { Monaco } from '@monaco-editor/react'

type ContentViewModeTP = 'header' | 'content' | 'footer'

type EditorCodeViewTP = Omit<MedicalReportConfigHtmlsResponseDTO, 'padding' | 'margin'>

interface ICompletionDisposable {
	dispose: VoidFunction
}

interface ICPProps {
	medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO
	onChange: (medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO) => void
}

export function TabEditMedicalReportConfigHtmlICP(props: ICPProps): React.ReactElement {
	const [view, setView] = useState<ContentViewModeTP>('header')
	const [completionDisposable, setCompletionDisposable] = useState<ICompletionDisposable | undefined>()

	function onChangeEditorCode(content: string, view: keyof EditorCodeViewTP): void {
		props.medicalReportHtmls[view] = content
		props.onChange(props.medicalReportHtmls)
	}

	useEffect(() => {
		return () => {
			if (completionDisposable?.dispose && typeof completionDisposable.dispose === 'function') {
				completionDisposable.dispose()
			}
		}
	}, [completionDisposable])

	function onEditorMount(_: unknown, monaco: Monaco): void {
		setCompletionDisposable(
			monaco.languages.registerCompletionItemProvider('html', {
				triggerCharacters: ['@'],
				provideCompletionItems: () => {
					const suggestions = Object.values(MedicalReportVarsEnum).map((keyword) => {
						const requiredPadi = REQUIRED_PADI_VALUES.includes(keyword) ? '[Obrigatório PADI]' : ''
						return {
							label: `${requiredPadi} ${keyword}`.trim(),
							kind: monaco.languages.CompletionItemKind.Keyword,
							insertText: `${keyword}@`,
							detail: requiredPadi,
						}
					})
					return { suggestions }
				},
			}),
		)
	}

	return (
		<>
			<FlexCP justifyContent={'center'} margin={{ bottom: 20 }}>
				<RadioGroupCP<ContentViewModeTP>
					type={'button'}
					options={[
						{ value: 'header', content: 'Cabeçalho' },
						{ value: 'content', content: 'Conteúdo' },
						{ value: 'footer', content: 'Rodapé' },
					]}
					onChange={setView}
					selected={view}
				/>
			</FlexCP>

			{view === 'header' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.headerHtml}
					onChange={(content) => onChangeEditorCode(content, 'headerHtml')}
					onEditorMount={onEditorMount}
					height={'1000px'}
					mode={'html'}
				/>
			)}

			{view === 'content' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.contentHtml}
					onChange={(content) => onChangeEditorCode(content, 'contentHtml')}
					onEditorMount={onEditorMount}
					height={'1000px'}
					mode={'html'}
				/>
			)}

			{view === 'footer' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.footerHtml}
					onChange={(content) => onChangeEditorCode(content, 'footerHtml')}
					onEditorMount={onEditorMount}
					height={'1000px'}
					mode={'html'}
				/>
			)}
		</>
	)
}
