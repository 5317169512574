import React from 'react'
import * as S from './CertificatesFooterStyles'

export function CertificatesFooterCP(): React.ReactElement {
	return (
		<S.CertificatesFooter>
			<S.Image src={`${process.env.PUBLIC_URL}/images/saasDefenceN1.png`} />
			<S.Image src={`${process.env.PUBLIC_URL}/images/icpBrasil.png`} />
		</S.CertificatesFooter>
	)
}
