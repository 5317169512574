import { EditorState } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import { CreateAutocompletePlugin } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/CreateAutocompletePlugin'
import { TextEditorConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorConfig'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'
import React, { Ref, useEffect, useState } from 'react'
import { IAutocompleteOption } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/inner/IAutocompleteOption'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TemplateSearchRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/TemplateSearchRequestDTO'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { List } from 'immutable'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	value: EditorState
	onChange: (editorState: EditorState) => void
	plugins: any[]
	examModality?: ExamModalityEnum
	forceReloadContent?: number
	appearance: {
		readonly: boolean
	}
}

/**
 */
export function ContentAreaICP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const [editorRef, setEditorRef] = useState<any>()

	const [autocompletePlugin, setAutocompletePlugin] = useState<any>()
	const [autocompletePhrases, setAutocompletePhrases] = useState(List<IAutocompleteOption>([]))

	const [loadedPhrases, setLoadedPhrases] = useState<IAutocompleteOption[]>()
	const searchPhrasesRequest = useRequest<ListResponseDTO<TemplateResponseDTO>>()
	useEffect(onSearchPhrasesRequestChange, [searchPhrasesRequest.isAwaiting])

	useEffect(init, [props.forceReloadContent])

	/**
	 * Inicializa
	 */
	function init(): void {
		setAutocompletePlugin(CreateAutocompletePlugin())

		// Seta undefined para resetar o editor
		setLoadedPhrases(undefined)

		const dto: TemplateSearchRequestDTO = {
			type: TemplateTypeEnum.PHRASE,
			modalities: !!props.examModality ? [props.examModality] : undefined,
		}
		searchPhrasesRequest.runRequest(TemplatesRequests.search(dto))
	}

	/**
	 * Retorno da requisicao
	 */
	function onSearchPhrasesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchPhrasesRequest)) return

		const result = searchPhrasesRequest.responseData!.list
		const phrases: IAutocompleteOption[] = result.map((phrase) => ({
			name: phrase.name,
			content: phrase.content as string,
		}))
		setLoadedPhrases(phrases ?? [])
	}

	/**
	 * Retorno para filtrar as frases
	 */
	function onAutocompleteSearchChange(search: { value: string }): void {
		const searchValue = search.value.substring(1, search.value.length).toLowerCase()
		const suggestionsList = List<IAutocompleteOption>(loadedPhrases ?? [])

		setAutocompletePhrases(
			suggestionsList
				.filter((item) => item!.name.toLowerCase().startsWith(searchValue))
				.take(10)
				.toList(),
		)
	}

	if (!loadedPhrases || !autocompletePlugin) return <LoadingCP margin={{ top: 80, bottom: 40 }} />

	return (
		<EditorAreaSCP>
			<EditorPageSCP
				isSmallScreen={screenSize.smd}
				onClick={() => {
					if (!!editorRef && typeof editorRef.focus === 'function') editorRef.focus()
				}}
			>
				<Editor
					ref={(ref: Ref<Editor>) => setEditorRef(ref)}
					plugins={[...props.plugins, autocompletePlugin]}
					editorState={props.value}
					readOnly={props.appearance.readonly}
					onChange={props.onChange}
					customStyleMap={TextEditorUtils.getCustomStyleMapProp()}
					spellCheck={true}
					stripPastedStyles={false}
				/>
				{!!autocompletePlugin && (
					<autocompletePlugin.CompletionSuggestions onSearchChange={onAutocompleteSearchChange} suggestions={autocompletePhrases} />
				)}
			</EditorPageSCP>
		</EditorAreaSCP>
	)
}

const EditorAreaSCP = styled.div`
	overflow: auto;
	height: 100%;
	padding: 55px 10px 10px 10px;
`

const EditorPageSCP = styled.div<{ isSmallScreen: boolean }>`
	box-sizing: content-box;
	cursor: text;
	min-height: calc(100vh - 250px);
	box-shadow: 2px 5px 10px ${ThemeProject.gray100};
	padding: 10px 20px;
	margin: 0 auto;
	background-color: ${ThemeFrameworkCommon.white};

	.DraftEditor-editorContainer {
		* {
			color: ${ThemeFrameworkCommon.black} !important;
		}
	}

	.DraftEditor-root {
		width: 100%;
		padding-top: 5px;
	}

	span[data-offset-key] span {
		${getDefaultStylesFixer()}
	}

	div.public-DraftStyleDefault-block {
		margin-bottom: 1em;
	}

	// O text-align sera inserido no filho como inline-text, adiciona o inline-text no pai
	div:has(> span[style*='text-align: center;']) {
		text-align: center;
	}

	div:has(> span[style*='text-align: right;']) {
		text-align: right;
	}

	div:has(> span[style*='text-align: justify;']) {
		text-align: justify;
	}

	div:has(> span[style*='text-align: left;']) {
		text-align: left;
	}

	&:focus {
		outline: none;
	}

	:global(.public-DraftEditor-content) {
		min-height: 140px;
	}

	.autocompleteSuggestions {
		background-color: ${ThemeFrameworkCommon.white};
		color: ${ThemeFrameworkCommon.black};
		display: flex;
		cursor: pointer;
		flex-direction: column;
		position: absolute;
		max-width: 440px;
		border-radius: 4px;
		padding: 2px;
		margin: 15px 0 0 15px;
		border: 1px solid ${ThemeProject.gray100};
		min-width: 220px;

		* {
			background-color: ${ThemeFrameworkCommon.white} !important;
			color: ${ThemeFrameworkCommon.black} !important;
		}
	}

	@media screen and (max-width: 640px) {
		table {
			width: 100%;
		}
	}
`

/**
 * Retorna bloco CSS para anular sobrescrita de estilos padrao
 * fixos do editor.
 */
function getDefaultStylesFixer(): string {
	const defaultStyleKeys = Object.keys(TextEditorConfig.DEFAULT_STYLES)
	let defaultStyleFixer = ''

	defaultStyleKeys.forEach((defaultStyleKey) => (defaultStyleFixer += `${defaultStyleKey}: inherit; `))

	return defaultStyleFixer
}
