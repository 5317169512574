import React from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { TemplateStatusEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateStatusEnum'

interface ICPProps {
	onRowClick: (template: TemplateResponseDTO) => void
	examCode: number
}

/**
 * Lista de templates.
 */
export function TableTemplatesForMedicalReportCP(props: ICPProps): JSX.Element {
	return (
		<TableFromApiCP<TemplateResponseDTO>
			shouldLoadData={1}
			row={{ onClick: props.onRowClick }}
			apiConfig={{
				requestConfigTP: () =>
					TemplatesRequests.searchForMedicalReport({
						examCode: props.examCode,
						status: TemplateStatusEnum.ACTIVE,
					}),
			}}
			filter={{ loadedListFilterColumnName: 'name', position: 'center' }}
			columns={[
				{
					title: 'Nome',
					render: (text, record) => (
						<>
							{record.name}
							<TagsExamModalitiesCP modalities={record.modalities} />
						</>
					),
				},
			]}
		/>
	)
}
