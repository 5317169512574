import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonGroupUrgencyTypeCP } from 'modules/exams/components/exams/button-group-urgency-type/ButtonGroupUrgencyTypeCP'
import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { ExamModalityEnum, ExamModalityLabelToEnEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamPendingResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingResponseDTO'
import { SelectContrastCP } from 'modules/exams/components/exams/select-contrast/SelectContrastCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectExamRequestingDoctorCP } from 'modules/clinic/components/selects/select-exam-requesting-doctor/SelectExamRequestingDoctorCP'
import { SelectExamExtraInfoCP } from 'modules/clinic/components/selects/select-exam-extra-info/SelectExamExtraInfoCP'

interface ICPProps {
	loadedExam: ExamPendingResponseDTO
	formStateManager: IFormStateManager<PendingExamFormModel>
}

/**
 * Drawer para triar um exam.
 */
export function FormExamPendingExamDataCP(props: ICPProps): JSX.Element {
	/**
	 * Ao selecionar um exam description.
	 */
	function onSelectExamDescription(val: SelectFullOptionTP, index: number): void {
		// Modalidade selecionada esta antes do primeiro espaço do nome
		const selectedModalityBrLabel = (val.label as string).split(' ')[0] as ExamModalityEnum

		// Atualiza o objeto selecionado
		const currentExams = props.formStateManager.getFieldValue('exams')

		// Monta o exame selecionado para atualizar a lista de exames
		const selectedExam = {
			code: val.value,
			modality: ExamModalityLabelToEnEnum[selectedModalityBrLabel],
			description: val.label,
			accessionNumber: props.formStateManager.getFieldValue('exams')?.[index]?.accessionNumber,
		}

		currentExams[index] = selectedExam
		props.formStateManager.changeFieldValue('exams', [...currentExams])
	}

	return (
		<>
			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Dados do Exame
			</TitleCP>

			<RowCP>
				<ColumnCP size={14}>
					<ButtonGroupUrgencyTypeCP
						doctorGroupCode={props.formStateManager.getFieldValue('doctorGroupCode')}
						clinicCode={props.loadedExam.clinicCode}
						type={'clinic'}
						formStateManager={props.formStateManager}
						fieldName={'urgencyType'}
					/>
				</ColumnCP>
				<ColumnCP size={10}>
					<BasicStyleWrapperCP margin={{ top: -20 }}>
						<SelectContrastCP formStateManager={props.formStateManager} fieldName={'contrasts'} />
					</BasicStyleWrapperCP>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={8}>
					<SelectExamRequestingDoctorCP
						formStateManager={props.formStateManager}
						fieldName={'requestingDoctor'}
						clinicCode={props.loadedExam.clinicCode}
					/>
				</ColumnCP>
				<ColumnCP size={4}>
					<InputCP
						label={'CRM'}
						formStateManager={props.formStateManager}
						fieldName={'requestingDoctorCrm'}
						value={props.formStateManager.getFieldValue('requestingDoctorCrm')}
						onChange={(value) => props.formStateManager.changeFieldValue('requestingDoctorCrm', value)}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<SelectExamExtraInfoCP formStateManager={props.formStateManager} fieldName={'extraInfo'} clinicCode={props.loadedExam.clinicCode} />
				</ColumnCP>
			</RowCP>

			{props.formStateManager.getFieldValue('exams')?.map((exam, index) => (
				<RowCP key={`row-${index}`}>
					<ColumnCP size={5}>
						<InputCP
							label={'Data'}
							value={DateUtils.formatDate(props.loadedExam.examDate, DateFormatEnum.BR_WITH_TIME_H_M)}
							disabled={true}
							required={true}
						/>
					</ColumnCP>
					<ColumnCP size={4}>
						<SelectExamModalityCP
							label={'Modalidade'}
							value={props.formStateManager.getFieldValue('exams')?.[0]?.modality}
							onChange={(val) => {
								const currentExams = props.formStateManager.getFieldValue('exams') as IPendingExamDescription[]
								currentExams[index].modality = val
								props.formStateManager.changeFieldValue('exams', [...currentExams])
							}}
							required={true}
							disabled={index !== 0}
						/>
					</ColumnCP>
					<ColumnCP size={9}>
						{!!props.loadedExam.doctorGroup &&
						props.formStateManager.getFieldValue('doctorGroup').config.shouldValidateExamDescription === true ? (
							<SelectDoctorGroupExamDescriptionsCP
								required={true}
								selectedDescription={props.formStateManager.getFieldValue('exams')?.[index]}
								onChange={(selected) => onSelectExamDescription(selected, index)}
								doctorGroupCode={props.formStateManager.getFieldValue('doctorGroupCode')}
								selectedModality={props.formStateManager.getFieldValue('exams')?.[0]?.modality}
								exam={{
									description: props.loadedExam.description,
									modality: props.loadedExam.modality,
									doctorGroupCode: props.loadedExam.doctorGroup?.code,
									clinicCode: props.loadedExam.clinicCode,
								}}
								showLearnedDescription={index === 0}
							/>
						) : (
							<InputCP
								label={'Nome do Exame'}
								required={true}
								formStateManager={props.formStateManager}
								value={props.formStateManager.getFieldValue('exams')?.[index].description}
								onChange={(val) => {
									const currentExams = props.formStateManager.getFieldValue('exams') as IPendingExamDescription[]
									currentExams[index].description = val
									props.formStateManager.changeFieldValue('exams', [...currentExams])
								}}
							/>
						)}
					</ColumnCP>
					<ColumnCP size={index > 0 ? 4 : 6}>
						<InputCP
							label={'Nº do Pedido'}
							formStateManager={props.formStateManager}
							value={props.formStateManager.getFieldValue('exams')?.[index].accessionNumber}
							onChange={(val) => {
								const currentExams = props.formStateManager.getFieldValue('exams') as IPendingExamDescription[]
								currentExams[index].accessionNumber = val
								props.formStateManager.changeFieldValue('exams', [...currentExams])
							}}
						/>
					</ColumnCP>
					<>
						{index > 0 && (
							<ColumnCP size={2}>
								<FlexCP justifyContent={'flex-end'}>
									<ButtonCP
										marginTop={25}
										icon={'delete'}
										type={'danger'}
										onClick={() => {
											const newExamsData = [...props.formStateManager.getFieldValue('exams')]
											newExamsData.splice(index, 1)
											props.formStateManager.changeFieldValue('exams', newExamsData)
										}}
									/>
								</FlexCP>
							</ColumnCP>
						)}
					</>
				</RowCP>
			))}
			<FlexCP justifyContent={'flex-end'}>
				<ButtonCP
					icon={'plus'}
					marginTop={20}
					onClick={() => {
						const newExamsData = [...props.formStateManager.getFieldValue('exams')]
						// Nova linha para ser preenchida
						newExamsData.push({ modality: props.formStateManager.getFieldValue('exams')?.[0]?.modality })
						props.formStateManager.changeFieldValue('exams', newExamsData)
					}}
				>
					Nova entrada
				</ButtonCP>
			</FlexCP>
		</>
	)
}
