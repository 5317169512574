import { TemplateFormModel } from 'modules/template/components/form-template/inner/TemplateFormModel'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'

interface ICPProps {
	type: TemplateTypeEnum
	doctorGroupCode?: number
	formStateManager: IFormStateManager<TemplateFormModel>
}

/**
 */
export function FormTemplateBasicDataICP(props: ICPProps): JSX.Element {
	/**
	 */
	function mountExamDescription(val: SelectFullOptionTP): string {
		const label = val.label as string

		// Modalidade selecionada esta antes do primeiro espaço do nome
		const selectedModalityBrLabel = label.split(' ')[0] as ExamModalityEnum

		// Monta o exame selecionado para atualizar a lista de exames
		return label.slice(selectedModalityBrLabel.length)
	}

	return (
		<RowCP>
			<ColumnCP size={props.type === TemplateTypeEnum.TEMPLATE ? 12 : 20}>
				<InputCP label={'Nome'} fieldName={'name'} formStateManager={props.formStateManager} required={true} />
			</ColumnCP>

			<ColumnCP size={4}>
				<SelectExamModalityCP
					label={props.type === TemplateTypeEnum.TEMPLATE ? 'Modalidade' : 'Modalidades'}
					formStateManager={props.formStateManager}
					fieldName={'modalities'}
					required={true}
					isMultiple={props.type === TemplateTypeEnum.PHRASE}
					excludedModalities={[ExamModalityEnum.CR_OIT]}
				/>
			</ColumnCP>

			{props.type === TemplateTypeEnum.TEMPLATE && !!props.formStateManager.getFieldValue('modalities') && (
				<ColumnCP size={8}>
					{!!props.doctorGroupCode ? (
						<SelectDoctorGroupExamDescriptionsCP
							selectedDescription={{ description: props.formStateManager.getFieldValue('examDescription') } as any}
							onChange={(selected) => props.formStateManager.changeFieldValue('examDescription', mountExamDescription(selected))}
							doctorGroupCode={props.doctorGroupCode}
							selectedModality={props.formStateManager.getFieldValue('modalities')}
						/>
					) : (
						<InputCP label={'Nome do Exame'} formStateManager={props.formStateManager} fieldName={'examDescription'} />
					)}
					<HelpCP
						text={
							'Se este campo estiver preenchido, no momento de laudar o exame que tenha exatamente esta descrição, já será carregado o template automaticamente'
						}
						type={'tooltip'}
					/>
				</ColumnCP>
			)}
		</RowCP>
	)
}
