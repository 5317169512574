import React, { useRef, useState } from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PatientPortalDataPdfCP } from 'modules/patient-portal/components/patient-portal-data-pdf/PatientPortalDataPdfCP'
import { useReactToPrint } from 'react-to-print'
import { PatientPortalAccessUtils } from 'submodules/beerads-sdk/services/patient-portal/utils/PatientPortalAccessUtils'
import { LinkHtmlCP } from 'submodules/nerit-framework-ui/common/components/link-html/LinkHtmlCP'
import * as S from './ExamPatientPortalDataStyles'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onEditData?: (closeDrawer?: boolean) => void
}

export function ExamPatientPortalDataCP(props: ICPProps): JSX.Element {
	const patientPortalDataPdfRef = useRef<HTMLDivElement | null>(null)
	const handlePrint = useReactToPrint({
		documentTitle: `br-laudos-exam_${props.exam.code}`,
		content: () => patientPortalDataPdfRef.current,
	})

	const [isPrinterReady, setIsPrinterReady] = useState(false)

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<FlexCP justifyContent={'space-between'}>
				<TitleCP textSize={'normal'} underLine={true}>
					Acesse o resultado:
				</TitleCP>

				<ButtonCP icon={'printer'} size={'small'} onClick={handlePrint} loading={!isPrinterReady} />
			</FlexCP>

			<S.CustomTextAndTitle
				title={'URL:'}
				text={
					<LinkHtmlCP href={PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppStateUtils.getDomain())} type={'link'} size={'small'}>
						{PatientPortalAccessUtils.getBeeRadsLaudoUrl(false)}
					</LinkHtmlCP>
				}
			/>

			<S.CustomTextAndTitle title={'Código do Exame:'} text={props.exam.code.toString()} />

			<S.CustomTextAndTitle title={'Data de Nascimento:'} text={DateUtils.formatDate(props.exam.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME)} />

			<S.CustomTextAndTitle title={'Nome Completo:'} text={props.exam.patient.name} />

			<PatientPortalDataPdfCP exam={props.exam} ref={patientPortalDataPdfRef} onPrinterReady={() => setIsPrinterReady(true)} />
		</BasicStyleWrapperCP>
	)
}
