import { TemplateFormModel } from 'modules/template/components/form-template/inner/TemplateFormModel'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import React, { useEffect, useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { FormTemplateBasicDataICP } from 'modules/template/components/form-template/inner/FormTemplateBasicDataICP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { TemplateSaveRequestDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/request/TemplateSaveRequestDTO'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/templates/TemplatesRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { EditorMedicalReportCP } from 'modules/exams/components/medical-report/editor-medical-report/EditorMedicalReportCP'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'
import styled from 'styled-components'

interface ICPProps {
	type: TemplateTypeEnum
	data?: {
		initialValues?: {
			content?: string
			modalities?: ExamModalityEnum[]
			owner: {
				// Um dos 3 deve ser preenchido
				clinicCode?: number
				doctorGroupCode?: number
				doctorCode?: number
			}
		}
		template?: TemplateResponseDTO
	}
	onSave: () => void
	appearance?: {
		wrapBasicDataOnCard: boolean
	}
}

/**
 * Formulario de cadastro (criar/editar) de TEMPLATES.
 */
export function FormTemplateCP(props: ICPProps): JSX.Element {
	const [initialized, setInitialized] = useState<boolean>(false)

	const [formValidator, setFormValidator] = useState<TemplateFormModel>(new TemplateFormModel())
	const formStateManager = useFormStateManager(formValidator)

	const saveTemplateRequest = useRequest<void>('none')
	useEffect(onSaveTemplateRequestChange, [saveTemplateRequest.isAwaiting])

	useEffect(init, [props.data])

	/**
	 * Inicializa
	 */
	function init(): void {
		setInitialized(false)

		if (!!props.data?.template) {
			setFormValidator(
				new TemplateFormModel({
					modalities: props.data.template.modalities[0],
					name: props.data.template.name,
					examDescription: props.data.template.examDescription,
					content: props.type === TemplateTypeEnum.TEMPLATE ? ({ json: props.data.template.content } as any) : props.data.template.content,
				}),
			)
			SystemUtils.sleep(100).then(() => setInitialized(true))
			return
		}

		setFormValidator(
			new TemplateFormModel({
				modalities: props.data?.initialValues?.modalities,
				content:
					props.type === TemplateTypeEnum.TEMPLATE
						? ({ json: props.data?.initialValues?.content ?? TextEditorUtils.getEmptyContent() } as any)
						: props.data?.initialValues?.content,
			}),
		)

		// Deixa um tempo em espera para recarregar o FORM
		SystemUtils.sleep(100).then(() => setInitialized(true))
	}

	/**
	 * Salva.
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		if (!props.data?.template?.owner && !props.data?.initialValues?.owner)
			return NotificationHelper.success('Ops!', 'Dono do template não foi definido')

		const formValues = formStateManager.getFormValues()!
		const dto: TemplateSaveRequestDTO = {
			contentHtml: (formValues.content as MedicalReportContentTP).html,
			type: props.type,
			name: formValues.name,
			content: props.type === TemplateTypeEnum.TEMPLATE ? (formValues.content as MedicalReportContentTP).json : formValues.content,
			modalities: Array.isArray(formValues.modalities) ? formValues.modalities : [formValues.modalities],
			examDescription: formValues.examDescription,
			owner: (props.data?.template?.owner ?? props.data?.initialValues?.owner)!,
		}

		if (!!props.data?.template) saveTemplateRequest.runRequest(TemplatesRequests.update(props.data.template.code, dto))
		else saveTemplateRequest.runRequest(TemplatesRequests.create(dto))
	}

	/**
	 * Retorno
	 */
	function onSaveTemplateRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveTemplateRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	if (!initialized) return <LoadingOverlayCP show={true} />

	return (
		<>
			<CardCP
				margin={!props.appearance?.wrapBasicDataOnCard ? '0px 20px 20px 20px' : undefined}
				bordered={!props.appearance?.wrapBasicDataOnCard ? false : true}
				innerSpacing={!props.appearance?.wrapBasicDataOnCard ? 'none' : undefined}
				overflow={'hidden'}
			>
				<FormTemplateBasicDataICP
					type={props.type}
					formStateManager={formStateManager}
					doctorGroupCode={props.data?.initialValues?.owner.doctorGroupCode}
				/>
			</CardCP>

			{props.type === TemplateTypeEnum.PHRASE && (
				<CardCP
					bordered={!props.appearance?.wrapBasicDataOnCard ? false : true}
					innerSpacing={!props.appearance?.wrapBasicDataOnCard ? 'none' : undefined}
				>
					<TextAreaCP label={'Conteúdo'} fieldName={'content'} formStateManager={formStateManager} required={true} minRows={10} maxRows={15} />
				</CardCP>
			)}
			{props.type === TemplateTypeEnum.TEMPLATE && !!formStateManager.getFieldValue('content')?.json && (
				<EditorSCP>
					<EditorMedicalReportCP
						initialContent={formStateManager.getFieldValue('content')?.json}
						onContentChange={(json, html) => {
							const content: MedicalReportContentTP = formStateManager.getFieldValue('content')
							content.json = json
							content.html = html
						}}
					/>
				</EditorSCP>
			)}

			<FlexCP justifyContent={'flex-end'} margin={{ top: 20, right: 20 }}>
				<ButtonCP type={'primary'} loading={saveTemplateRequest.isAwaiting} onClick={save}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}

const EditorSCP = styled.div`
	margin: 0 20px;
`
