import { EnvironmentEnum } from '_old/main/common/enums/EnvironmentEnum'

/**
 * Definicao de parametros de configuracao globais do app.
 */
export class AppConfig {
	readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum
	readonly reduxEncryptKey = 'bmVyaXRzZWvyZX1rZXk='

	readonly defaultTimeZone = 'America/Sao_Paulo'

	readonly beeradsViewerBaseUrl: string
	readonly uiBaseUrl: string
	readonly apiBaseUrl: string
	readonly api2BaseUrl: string
	readonly leoRadUrl: string

	readonly space4LeadsApiBaseUrl: string

	/** Instancia unica da classe. */
	private static instance: AppConfig

	private constructor() {
		switch (this.environment) {
			case EnvironmentEnum.DEV:
				this.uiBaseUrl = 'http://localhost:3000'
				this.apiBaseUrl = 'http://localhost:3001'
				this.api2BaseUrl = 'http://localhost:3001'
				this.beeradsViewerBaseUrl = 'http://localhost:3000/viewer'
				this.space4LeadsApiBaseUrl = 'http://localhost:3005'
				this.leoRadUrl = 'https://stg.leorad.com.br'
				break

			case EnvironmentEnum.PROD:
				this.uiBaseUrl = 'https://app.vx.med.br'
				this.apiBaseUrl = 'https://api-3.vx.med.br'
				this.api2BaseUrl = 'https://api.vx.med.br'
				this.beeradsViewerBaseUrl = 'https://viewer.vx.med.br/viewer'
				this.space4LeadsApiBaseUrl = 'https://api.space4leads.com'
				this.leoRadUrl = 'https://app.leorad.com.br'
				break

			case EnvironmentEnum.PROD2:
				this.uiBaseUrl = 'https://app-2.vx.med.br'
				this.apiBaseUrl = 'https://api.vx.med.br'
				this.api2BaseUrl = 'https://api.vx.med.br'
				this.beeradsViewerBaseUrl = 'https://viewer.vx.med.br/viewer'
				this.space4LeadsApiBaseUrl = 'https://api.space4leads.com'
				this.leoRadUrl = 'https://app.leorad.com.br'
				break

			case EnvironmentEnum.HOM:
				this.uiBaseUrl = 'https://hom.app.vx.med.br'
				this.apiBaseUrl = 'https://api-dev.vx.med.br'
				this.api2BaseUrl = 'https://api-dev.vx.med.br'
				this.beeradsViewerBaseUrl = 'https://hom.viewer.vx.med.br/viewer'
				this.space4LeadsApiBaseUrl = 'https://api.space4leads.com'
				this.leoRadUrl = 'https://app.leorad.com.br'
				break

			case EnvironmentEnum.BETA:
				this.uiBaseUrl = 'https://beta.app.vx.med.br'
				this.apiBaseUrl = 'https://api-dev.vx.med.br'
				this.api2BaseUrl = 'https://api-dev.vx.med.br'
				this.beeradsViewerBaseUrl = 'https://hom.viewer.vx.med.br/viewer'
				this.space4LeadsApiBaseUrl = 'https://api.space4leads.com'
				this.leoRadUrl = 'https://app.leorad.com.br'
				break

			case EnvironmentEnum.TEST:
			default:
				throw new Error('Ambiente não configurado!')
		}
	}

	/** Gera & retorna instancia unica da classe. */
	static getInstance(): AppConfig {
		if (!this.instance) this.instance = new AppConfig()
		return this.instance
	}
}
