import React, { useEffect, useState } from 'react'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { DoctorOnMedicalTimetableSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableSaveRequestDTO'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalTimetableGroupByTP } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableGroupByTP'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { MedicalTimetableAssignFormUtils } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormUtils'
import { BoxMedicalTimetableDoctorWeekRecurrencyConfigCP } from 'modules/medical-timetable/components/box-medical-timetable-doctor-week-recurrency-config/BoxMedicalTimetableDoctorWeekRecurrencyConfigCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { BoxMedicalTimetableDoctorWeekSpotConfigItemICP } from 'modules/medical-timetable/components/box-medical-timetable-doctor-week-recurrency-config/BoxMedicalTimetableDoctorWeekSpotConfigItemICP'
import { DoctorOnMedicalTimetableUpdateRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableUpdateRequestDTO'
import { DoctorScheduleSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorScheduleSearchRequestDTO'
import { DoctorScheduleSearchResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorScheduleSearchResponseDTO'

export type MedicalTimetableAssignType = 'spot' | 'recurrent'

interface ICPProps extends BasicModalCPProps {
	doctorGroupCode: number
	period: MedicalTimetablePeriodEnum
	dayOfWeek: WeekDaysEnum
	modality: ExamModalityEnum
	groupBy: MedicalTimetableGroupByTP
	id: MedicalTimetableIdEnum
	date: Date
	isRecurrent?: boolean
	editData?: {
		doctorCode: number
		type: MedicalTimetableAssignType
	}
}

/**
 */
export function ModalMedicalTimetableAssignCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<MedicalTimetableAssignFormModel>(new MedicalTimetableAssignFormModel({ type: 'recurrent' }))
	const formStateManager = useFormStateManager<MedicalTimetableAssignFormModel>(formModel)

	const getDoctorMedicalTimetableRequest = useRequest<DoctorScheduleSearchResponseDTO>()
	useEffect(onGetDoctorMedicalTimetableRequestChange, [getDoctorMedicalTimetableRequest.isAwaiting])

	const assignRequest = useRequest<void>('none')
	useEffect(onAssingRequestChange, [assignRequest.isAwaiting])

	useEffect(() => onSelectDoctor(formStateManager.getFieldValue('doctorCode')), [formStateManager.getFieldValue('doctorCode')])
	useEffect(init, [props.visible])

	/**
	 */
	function init(): void {
		setFormModel(new MedicalTimetableAssignFormModel({}))
		if (!props.visible) return

		if (!!props.editData) {
			onSelectDoctor(props.editData.doctorCode)
			setFormModel(
				new MedicalTimetableAssignFormModel({
					type: props.editData.type,
					doctorCode: props.editData.doctorCode,
				}),
			)
		}
	}

	/**
	 */
	function onSelectDoctor(doctorCode: number): void {
		if (!doctorCode) return

		//quando quero atribuir, usa isAssign para buscar se o médico já possui alguma recorrência agendada e exibe na tela
		const dto: DoctorScheduleSearchRequestDTO = {
			userDoctorCode: doctorCode,
			doctorGroupCode: props.doctorGroupCode,
			period: props.period,
			modality: props.modality,
			groupBy: props.groupBy,
			id: props.id,
			date: props.date,
			isRecurrence: !!props.editData ? props.isRecurrent : true,
		}

		getDoctorMedicalTimetableRequest.runRequest(MedicalTimetableRequests.getDoctorScheduled(dto))
	}

	/**
	 */
	function onGetDoctorMedicalTimetableRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				{ request: getDoctorMedicalTimetableRequest, mustReport404: false },
				NotificationHelper.DEFAULT_ERROR_GET_MESSAGE,
			)
		)
			return

		const result = getDoctorMedicalTimetableRequest.responseData

		// Caso nao tenha escala cadastrada
		if (!result) return

		//Atualiza o formModel com os valores que veio da requisição
		setFormModel(MedicalTimetableAssignFormUtils.getFormModel(result, formModel, !!props.isRecurrent, !!props.editData))
	}

	/**
	 */
	function assignDoctor(): void {
		if (!FormStateManagerUtils.validateRequiredFields(formStateManager)) return

		const formValues = formStateManager.getFormValues()!

		if (formValues.type === 'spot') {
			const spotValues = MedicalTimetableAssignFormUtils.formatSpotDto(formStateManager.getFieldValue('spot'))

			if (ArrayUtils.isEmpty(spotValues)) {
				NotificationHelper.error('Ops', 'Selecione o dia para realizar a alocação')
				return
			}

			const dto: DoctorOnMedicalTimetableSaveRequestDTO = {
				doctorGroupCode: props.doctorGroupCode,
				modality: props.modality,
				groupBy: props.groupBy,
				userDoctorCode: formValues.doctorCode,
				period: props.period,
				id: props.id,
				date: props.date,
				description: formValues.description,
				weekDayHourRanges: spotValues,
			}

			//Se for edição chama o endpoint de update
			if (!!props.editData) {
				const dto: DoctorOnMedicalTimetableUpdateRequestDTO = {
					doctorGroupCode: props.doctorGroupCode,
					userDoctorCode: formValues.doctorCode,
					description: formValues.description,
					weekDayHourRanges: spotValues,
				}

				assignRequest.runRequest(MedicalTimetableRequests.updateDoctorToSchedule(formValues.code, dto))
				return
			}

			//se não, chama o endpoint de create
			assignRequest.runRequest(MedicalTimetableRequests.addDoctorToSchedule(dto))
			return
		}

		const rules = MedicalTimetableAssignFormUtils.formatRulesDto(formStateManager.getFieldValue('recurrency'))
		if (ArrayUtils.isEmpty(rules)) {
			NotificationHelper.error('Ops', 'Para alocação recorrente adicione regra para pelo menos 1 dia da semana')
			return
		}

		const recurrencyValues = MedicalTimetableAssignFormUtils.formatRecurrencyDto(formStateManager.getFieldValue('recurrency'))
		const dto: DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			modality: props.modality,
			groupBy: props.groupBy,
			userDoctorCode: formValues.doctorCode,
			period: props.period,
			id: props.id,
			rules: rules!,
			dateRange: { beginDate: props.date, endDate: formValues.endDate?.toDate() },
			description: formValues.description,
			weekDayHourRanges: recurrencyValues,
		}
		assignRequest.runRequest(MedicalTimetableRequests.addDoctorRecurrentlyToSchedule(dto))
		return
	}

	/**
	 */
	function onAssingRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				assignRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			visible={props.visible}
			onCancel={props.onCancel}
			title={'Alocar médico na escala'}
			onOk={assignDoctor}
			actionLoading={assignRequest.isAwaiting}
			width={530}
			top={10}
		>
			<SelectDoctorGroupPeopleCP
				role={UserTypeEnum.DOCTOR}
				namesConfig={{
					showSpecialty: true,
					showTeam: true,
				}}
				formStateManager={formStateManager}
				formField={'doctorCode'}
				doctorGroupCode={props.doctorGroupCode}
				disabled={!!props.editData}
			/>
			<LoadingOverlayCP show={getDoctorMedicalTimetableRequest.isAwaiting} />

			{!!formStateManager.getFieldValue('doctorCode') && (
				<>
					<TextAreaCP label={'Observações'} formStateManager={formStateManager} fieldName={'description'} />

					<FlexCP justifyContent={'center'} margin={{ bottom: 20, top: 20 }}>
						<RadioGroupCP<MedicalTimetableAssignType>
							type={'button'}
							buttonWidth={110}
							formStateManager={formStateManager}
							fieldName={'type'}
							options={[
								{ value: 'spot', content: 'Spot' },
								{ value: 'recurrent', content: 'Recorrente' },
							]}
							disabled={!!props.editData}
						/>
					</FlexCP>
					{formStateManager.getFieldValue('type') === 'spot' ? (
						<BoxMedicalTimetableDoctorWeekSpotConfigItemICP
							formStateManager={formStateManager}
							weekDay={props.dayOfWeek}
							period={props.period}
						/>
					) : (
						<BoxMedicalTimetableDoctorWeekRecurrencyConfigCP formStateManager={formStateManager} period={props.period} />
					)}
				</>
			)}
		</ModalCP>
	)
}
