import React, { useEffect, useState } from 'react'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { PreviewMedicalReportConfigCP } from '../preview-medical-report-config/PreviewMedicalReportConfigCP'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalReportConfigSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/request/MedicalReportConfigSaveRequestDTO'
import { MedicalReportConfigRequests } from 'submodules/beerads-sdk/services/clinics/medical-report-config/MedicalReportConfigRequests'
import { TabsEditMedicalReportConfigCP } from 'modules/clinic/components/tabs-edit-medical-report-config/TabsEditMedicalReportConfigCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { MedicalReportConfigResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigResponseDTO'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps extends BasicModalCPProps {
	clinicCode: number
}

export function ModalMedicalReportTemplateCP(props: ICPProps): JSX.Element {
	const [isContentHighlighted, setIsContentHighlighted] = useState<boolean>(false)
	const [medicalReportHtmls, setMedicalReportHtmls] = useState<MedicalReportConfigHtmlsResponseDTO>()

	const getMedicalReportConfigRequest = useRequest<MedicalReportConfigResponseDTO>()
	useEffect(onGetMedicalReportConfigRequestChange, [getMedicalReportConfigRequest.isAwaiting])

	const saveMedicalReportConfigRequest = useRequest<void>('none')
	useEffect(onSaveMedicalReportConfigRequestChange, [saveMedicalReportConfigRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		if (!props.visible) return

		getMedicalReportConfigRequest.runRequest(MedicalReportConfigRequests.get(props.clinicCode))
	}

	function onGetMedicalReportConfigRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalReportConfigRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getMedicalReportConfigRequest.responseData!

		setMedicalReportHtmls({
			headerHtml: result.headerTemplateHtml,
			contentHtml: result.documentWrapperHtml,
			footerHtml: result.footerTemplateHtml,
			margin: result.margin,
			padding: result.padding,
		})
	}

	/**
	 * Salva.
	 */
	function save(): void {
		if (!medicalReportHtmls) return NotificationHelper.error('Ops', 'HTML nao foi definido, contate o administrador do sistema')

		const dto: MedicalReportConfigSaveRequestDTO = {
			clinicCode: props.clinicCode,
			headerHtml: medicalReportHtmls.headerHtml,
			contentHtml: medicalReportHtmls.contentHtml,
			footerHtml: medicalReportHtmls.footerHtml,
			margin: medicalReportHtmls.margin,
			padding: medicalReportHtmls.padding,
		}

		saveMedicalReportConfigRequest.runRequest(MedicalReportConfigRequests.update(props.clinicCode, dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveMedicalReportConfigRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveMedicalReportConfigRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			title={'Editar Modelo do Laudo'}
			visible={props.visible}
			onCancel={props.onCancel}
			width={'100%'}
			top={0}
			onOk={save}
			actionLoading={saveMedicalReportConfigRequest.isAwaiting}
			loading={getMedicalReportConfigRequest.isAwaiting}
		>
			{medicalReportHtmls ? (
				<RowCP>
					<ColumnCP xl={8} xxl={12}>
						<TabsEditMedicalReportConfigCP
							shouldHighlightContent={isContentHighlighted}
							medicalReportHtmls={medicalReportHtmls}
							onChange={(medicalReportHtmlsChanged) => setMedicalReportHtmls({ ...medicalReportHtmlsChanged })}
						/>
					</ColumnCP>

					<ColumnCP xl={16} xxl={12}>
						<StyledFlexCP>
							<SwitchCP label={'Destacar conteúdo?'} value={isContentHighlighted} onChange={setIsContentHighlighted} />
						</StyledFlexCP>
						<PreviewMedicalReportConfigCP
							shouldHighlightContent={isContentHighlighted}
							medicalReportHtmls={medicalReportHtmls}
							margin={medicalReportHtmls.margin}
						/>
					</ColumnCP>
				</RowCP>
			) : (
				<EmptyCP description={'Template não encontrado'} />
			)}
		</ModalCP>
	)
}

const StyledFlexCP = styled(FlexCP)`
	justify-content: center;

	> div {
		width: auto;
	}
`
