import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TemplateTypeEnum, TemplateTypeLabelEnum } from 'submodules/beerads-sdk/services/templates/templates/enums/TemplateTypeEnum'
import React from 'react'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { FormTemplateCP } from 'modules/template/components/form-template/FormTemplateCP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/templates/dtos/response/TemplateResponseDTO'

interface ICPProps extends BasicModalCPProps {
	type: TemplateTypeEnum
	data?: {
		initialValues?: {
			content?: string
			modalities?: ExamModalityEnum[]
			owner: {
				// Um dos 3 deve ser preenchido
				clinicCode?: number
				doctorGroupCode?: number
				doctorCode?: number
			}
		}
		template?: TemplateResponseDTO
	}
}
/**
 * Modal para criação de frases de laudo.
 */
export function ModalTemplateCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP
			visible={props.visible}
			onCancel={props.onCancel}
			title={TemplateTypeLabelEnum[props.type]}
			width={props.type === TemplateTypeEnum.TEMPLATE ? 900 : undefined}
			top={10}
			noFooter={true}
		>
			<FormTemplateCP type={props.type} onSave={props.onSave} data={props.data} />
		</ModalCP>
	)
}
